import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment-timezone';
import { faCheckCircle, faExclamationTriangle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../utils/config";
import ModalBackground from "../ModalBackground/ModalBackground";
moment.tz.setDefault('Australia/Brisbane');

const ImportSummary = ({imports, handleClose}) => (
    <>
        <ModalBackground handleClose={handleClose} />
        <aside id="popoverForImportSuccessSummary" className="topLeft">
            <div className="closeButton">
                <FontAwesomeIcon icon={faTimesCircle} onClick={handleClose} />
            </div>
            <h4>Daily data summary</h4>
            <table>
                <tbody>
                    <ImportSummaryRow timestamp={imports.student_one_load} text={'Enrolment data (StudentOne) last updated'}/>
                    <ImportSummaryRow timestamp={imports.moodle_load} text={'Assignment, Activity and Groups data (Moodle) last updated'}/>
                    <ImportSummaryRow timestamp={imports.last_crm} text={'Interactions data (CRM) last updated'}/>
                </tbody>
            </table>
        </aside>
    </>
);

const ImportSummaryRow = ({text, timestamp}) => (
    <tr>
        <td>
            <ImportSummaryRowIndicator timestamp={timestamp}/>
        </td>
        <td>
            {`${text} ${moment(timestamp).calendar()}`}
        </td>
    </tr>
);


const ImportSummaryRowIndicator = ({timestamp}) => {
    return moment().diff(moment(timestamp), 'days') < 1 ?
        <FontAwesomeIcon icon={faCheckCircle} style={{color: config.colors.cquGreen}}/> :
        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: config.colors.medium}}/>;
}

export default ImportSummary;
