import React, { useContext } from 'react';
import { Hint, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis } from 'react-vis';
import { AssetsContext } from '../../../../context/AssetsContext';
import { mapBeginDateToShortDates, mapBeginDateToWeeks } from '../../../../utils/calendar';
import { config } from '../../../../utils/config';
import { filterIntegers, withWidget, renderYLabel, renderXLabel } from '../../../../utils/graph';
import moment from 'moment-timezone';
import useRouteParams from '../../../../hooks/useRouteParams';
import { SamActivityContext } from '../../context/SamActivityContext';
import { color_scheme, truncate } from '../../../../utils/utils';
import { DefinitionCodes, DefinitionInfo } from '../../../../components';
moment.tz.setDefault('Australia/Brisbane');

const SEPARATOR = ';';
const PLOT = {
    height: 280,
    width: 960,
    left: 60,
    bottom: 50,
};

/**
 * This component displays a bar graph of activity by section from the sam activities per enrollment.
 * It uses the react-vis library for rendering the graph.
 *
 * @param {Array} enrollments - An array of enrollment objects containing information about the units and students.
 * @param {Object} hover - An object representing the assessment that is currently being hovered over by the user.
 * @param {Function} handleMouseOver - A function to handle mouseover events on the graph.
 * @param {Function} handleMouseOut - A function to handle mouseout events on the graph.
 * @returns {JSX.Element} - A JSX element representing the assessment graph.
 */
const SectionActivityGraph = ({ enrollments, hover, handleMouseOver, handleMouseOut }) => {
    const { term } = useRouteParams();
    const { termInfo } = useContext(AssetsContext);
    const { samActivities } = useContext(SamActivityContext);
    const scheme = color_scheme(enrollments.flatMap(enrollments => enrollments.unit.code), [config.colors.cquBlue, config.colors.cquBlue50]);
    const isVet = term === 'VET';
    const thisTerm = termInfo.displayedTerm;

    // Map activities by section and index by unit code.
    const result = enrollments.reduce((acc, enrollment) => {
        const unitCode = enrollment.unit.code;

        if (!acc[unitCode]) {
            acc[unitCode] = [];
        }

        // Filter the samActivities so that they are only for the targeted enrollments.
        const activities = samActivities.filter(sam => enrollment.id === sam.enrollment_id);

        // Add assessments by week.
        activities.forEach((activity) => {
            if (activity.section_number === 0 || activity.course_module_id !== 0 ) {
                // Only target section info. For now.
                return;
            }
            let activityWeek = thisTerm.weeks.data.find(({start}) => start === `${activity.begin_date} 00:00:00`);
            let weekName = (activityWeek === undefined) ? `n/a - ${activity.begin_date}` : activityWeek.long_name;
            acc[unitCode].push({
                // We join the section number and section name, because section name could be non unique.
                x: [activity.section_number, activity.section_name].join(SEPARATOR),
                y: activity.activity,
                sam: activity,
                week: weekName,
                color: hover?.sam?.id === activity.id ? config.colors.cquGreen : scheme[unitCode],
                unitCode: unitCode,
            });
        });

        return acc;
    }, {});

    return (
        <div className={'UnitWidget UnitWidgetAutoWidth'}>
            <h4>Activity by Tile<DefinitionInfo code={DefinitionCodes.ACTIVITY} /></h4>
            <XYPlot
                height={PLOT.height}
                width={PLOT.width}
                stackBy="y"
                xType="ordinal"
                margin={{
                    left: PLOT.left,
                    bottom: PLOT.bottom,
                }}
            >
                {hover && <Hint value={hover} className='plotTooltip'>
                    <div>
                        <h3>{hover.sam.section_name}</h3>
                        <p>Number of clicks: {hover.sam.activity}</p>
                        <p>Unit: {hover.unitCode}</p>
                        <p>During: { hover.week}</p>
                    </div>
                </Hint>}
                {/* The x value contains section number and section name, we to display only section name. */}
                <XAxis height={50} tickFormat={val => truncate(val.split(SEPARATOR).slice(1).join(SEPARATOR), {len: 16})} tickLabelAngle={-25} />
                <YAxis width={60} tickFormat={filterIntegers} />
                <HorizontalGridLines />
                {renderYLabel('Moodle Clicks', PLOT)}
                {Object.entries(result).map(([key, value]) =>
                    <VerticalBarSeries
                        key={key}
                        data={value}
                        colorType={'literal'}
                        stroke="#ffffff"
                        onValueMouseOver={handleMouseOver}
                        onValueMouseOut={handleMouseOut}
                    />
                )}
            </XYPlot>
        </div>
    );
};

export default withWidget(SectionActivityGraph);
