import React, { useState, useEffect } from 'react';
import { fetchSamActivitiesInTermForStudent } from '../../../utils/fetch';

/*
 * SamActivityContext contains all sam activities for a given year, term and student id.
 */
const SamActivityContext = React.createContext();

/**
 * This FunctionComponent is the Provider that gives access to the context to its children.
 *
 * @param {JSX.Element} children Child components, which will have access to assets.
 * @param {number} year The year of the term to fetch
 * @param {string} term The term of the term to fetch
 * @param {number} student The id of the student for which to fetch the samActivities
 */
const SamActivityProvider = ({ year, term, student, children }) => {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [samActivities, setSamActivities] = useState([]);

    useEffect(() => {
        fetchSamActivitiesInTermForStudent(parseInt(year), term, parseInt(student)).then(result => {
            setSamActivities(result);
            setLoading(false);
        }).catch(error => setError(error));
    }, [year, term, student]);

    const isLoading = loading;
    const isError = error !== undefined;
    const isSuccess = !isError && !isLoading;
    const value = {
        isLoading,
        isSuccess,
        isError,
        error,
        samActivities
    };

    return (
        <SamActivityContext.Provider value={value}>
            {children}
        </SamActivityContext.Provider>
    );
};

export { SamActivityContext, SamActivityProvider };
