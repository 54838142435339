import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Loader } from '../../components';
import { AssetsContext, AssetsContextProvider } from '../../context/AssetsContext';
import { ModalProvider } from '../../context/ModalContext';
import { gpaBand, lastLoginBand, passRateBand } from '../../utils/bands';
import { calcPassRate, sendLog } from '../../utils/utils';
import Error from '../Error/Error';
import { StudentAvatar, StudentInfo, StudentDetails, EnrollmentSelection, VetEnrollmentSelection, InteractionTable, InteractionGraph, ProgressInCourseWidget, DaysSinceLogin, AssessmentsSubmitted, AssessmentsDue, WeeklyActivityWidget, AssessmentExtensionsWidget, AssessmentsWidget, SectionActivityWidget } from './components';
import { AssessmentProvider } from './context/AssessmentContext';
import { faPollH, faUser } from '@fortawesome/free-solid-svg-icons';
import SelectedEnrollmentsContext, { SelectedEnrollmentsProvider } from '../../context/SelectedEnrollmentsContext';
import moment from 'moment-timezone';
import { CURRENT_VET_YEAR_LABEL, CURRENT_VET_YEAR_VALUE, LOG } from '../../utils/constants';
import { HeaderContext, HeaderContextProvider } from '../../context/HeaderContext';
import useEnrollments from '../../hooks/useEnrollments';
import useRouteParams from '../../hooks/useRouteParams';
import { SamActivityProvider } from './context/SamActivityContext';
moment.tz.setDefault('Australia/Brisbane');

/**
 * The student dashboard is wrapped. This allows the cohort to use context.
 */
const StudentDashboard = ({ incognito }) => {
    const { year, term } = useRouteParams();

    return (
        <HeaderContextProvider incognito={incognito}>
            <AssetsContextProvider year={year} term={term}>
                <SelectedEnrollmentsProvider dashboard={LOG.DASHBOARD.STUDENT}>
                    <ModalProvider>
                        <Main/>
                    </ModalProvider>
                </SelectedEnrollmentsProvider>
            </AssetsContextProvider>
        </HeaderContextProvider>
    );
}

const Main = () => {
    const { year: parYear, term: parTerm, student: parStudent, unit: parUnit, raw } = useRouteParams();
    const home = useContext(HeaderContext);
    const assets = useContext(AssetsContext);
    const [hasSelectedDefaultAlready, setHasSelectedDefaultAlready] = useState(false);
    const { selectedEnrollments, addSelectedEnrollment } = useContext(SelectedEnrollmentsContext);
    const term = assets.isSuccess ? assets.termInfo.displayedTerm : null;
    const isVet = parTerm.toUpperCase() === 'VET' ? true : false;
    const enrollments = useEnrollments(parYear, parTerm, new URLSearchParams(`path=~${parStudent}`));
    const [tab, setTab] = useState('overview');
    const [selEnrollments, setSelEnrollments] = useState(null); // Null, in beginning array after.
    const isLoading = assets.isLoading || enrollments.isLoading || home.isLoading;
    const isError = assets.isError || enrollments.isError || home.isError;

    const handleTab = (tabName) => {
        setTab(tabName);
        sendLog('App\\Events\\StudentDashboard\\Opened', 'r', LOG.ACTION.OPEN, LOG.TARGET.TAB, LOG.DASHBOARD.STUDENT, {
            tab: tabName,
            term: { year: parYear, term: parTerm },
            student_id: parStudent,
            unit_code: parUnit,
        });
    };

    /**
     * For event logging a page view.
     */
    useEffect(() => {
        if (enrollments.isSuccess) {
            sendLog("App\\Events\\StudentDashboard\\Viewed", 'r', LOG.ACTION.VIEW, LOG.TARGET.PAGE, LOG.DASHBOARD.STUDENT, {
                term: { year: parYear, term: parTerm },
                student_id: parStudent,
                unit_code: parUnit,
            });
        }
    }, [enrollments.isSuccess, parYear, parTerm, parStudent, parUnit]);

    if (isLoading && !isError) {
        return <Loader
            isLoadingAssets={assets.isLoading}
            isLoadingHome={home.isLoading}
            isLoadingEnrollments={enrollments.isLoading}
        />;
    }

    // Stop here if the data is still loading or has errored.
    if (isError || !term) {
        if (!term) {
            return <Error error={{ code: 404, title: "Term not found", message: "The term provided in the URL is incorrect.", }} />
        }
        return <Error error={assets.error || enrollments.error || home.error} />
    }

    enrollments.data.forEach((enrollment) => {
        enrollment.student.age = moment().diff(enrollment.student.date_birth, 'years');
        enrollment.lastLoginBand = lastLoginBand(enrollment.last_login);
        enrollment.gpaBand = gpaBand(enrollment.student_course.gpa);
        enrollment.passRate = calcPassRate(
            enrollment.student_course.units_passed + enrollment.student_course.units_failed,
            enrollment.student_course.units_passed
        );
        enrollment.passRateBand = passRateBand(enrollment.passRate, home.config);
        enrollment.term = assets.terms.find(({ id }) => id === enrollment.term_id);
        enrollment.unit = assets.units.find(({ id }) => id === enrollment.unit_id);
        enrollment.course = assets.courses.find(({ id }) => id === enrollment.course_id);
        enrollment.college = assets.colleges.find(({ code }) => code === enrollment.college_code);
        enrollment.campus = assets.campuses.find(({ code }) => code === enrollment.campus_code);
        enrollment.school = assets.schools.find(({ code }) => code === enrollment.school_code);
    });

    // Error handling.
    const defaultEnrollment = enrollments.data.find(( enr ) => enr.unit.code === parUnit);
    if (!defaultEnrollment) {
        return <Error error={{ code: 404, title: "Unit not found", message: "The unit provided in the URL is incorrect.", }} />
    }

    // Grabs the unit from the default enrollment.
    const unit = defaultEnrollment.unit;
    if (!unit) {
        return <Error error={{ code: 404, title: "Unit not found", message: "The unit provided in the URL is incorrect.", }} />
    }

    // Grabs the student from the default enrollment.
    const student = defaultEnrollment.student;
    if (!student) {
        return <Error error={{ code: 404, title: "No enrollments for this term", message: "The student and term provided in the URL are incorrect.", }} />
    }

    // Count interactionsSent per enrollments and count of interactionCases of the default (cases are all the same foreach enrollment ).
    const totalInteractions = enrollments.data.reduce((acc, { interactionsSent }) => acc + interactionsSent.length, 0) + defaultEnrollment.interactionCases.length;

    // On this dashboard we want to load a default enrollment, but only once.
    if (selectedEnrollments.size === 0 && !hasSelectedDefaultAlready) {
        addSelectedEnrollment(defaultEnrollment);
        setHasSelectedDefaultAlready(true);
    }

    // Get the enrollment objects of the selEnrollments, when selEnrollments is null then select the defaultEnrollment.
    const filtered = Array.from(selectedEnrollments.values());

    const courseEnrollments = enrollments.data.reduce( (acc, {course, student_course}) => {
        const key = student_course.course_id;

        const existingIndex = acc.findIndex(item => item.course_id === key);

        if (existingIndex === -1) {
            student_course.course = course;
            acc.push(student_course);
          }

        return acc;
    }, []);

    return (
        <AssessmentProvider year={parseInt(parYear)} term={parTerm} student={student.id}>
            <SamActivityProvider year={parseInt(parYear)} term={parTerm} student={student.id}>
                <Header
                    config={home.config}
                    termInfo={assets.termInfo}
                    imports={home.imports}
                    user={home.user}
                    notices={home.notices}
                    services={[
                        [`Cohort Dashboard for ${parUnit}`, `/cohort/${raw.year}/${raw.term}?path=:${parUnit}`, faPollH],
                        [`Unit Dashboard for ${parUnit}`, `/unit/${raw.year}/${raw.term}/${parUnit}`, faUser],
                    ]}
                    incognito={home.incognito}
                    handleUpdateIncognito={home.setIncognito}
                    breadcrumbs={[
                        ['Student Dashboard'],
                        [`${parYear === CURRENT_VET_YEAR_VALUE ? CURRENT_VET_YEAR_LABEL : raw.year} ${raw.term}`],
                        [student.id],
                        [`${unit.code}: ${unit.name}`]
                    ]}
                />
                <main>
                    <div className="centeredWideColumn">
                        <div className="infoHeader">
                            <article>
                                <StudentAvatar incognito={home.incognito} studentId={parStudent}/>
                                <StudentInfo incognito={home.incognito} student={student} course={enrollments.data.find(x => x).course} />
                            </article>
                        </div>
                        <div className="tabPanel">
                            <span className={tab === 'overview' ? "tab tabSelected" : "tab"} onClick={() => handleTab('overview')}>Overview</span>
                            <span className={tab === 'interactions' ? "tab tabSelected" : "tab"} onClick={() => handleTab('interactions')}>Interactions ({totalInteractions})</span>
                            <span className={tab === 'details' ? "tab tabSelected" : "tab"} onClick={() => handleTab('details')}>Student Details</span>
                        </div>
                    </div>
                    <div>
                        <h2 style={{margin: '1rem'}}>{unit.code} {unit.name}</h2>
                        { tab === 'overview' && (
                            <div className='studentDetailsColumns'>
                                <div className='studentDetailsLeftColumn'>
                                    {isVet ? <div><VetEnrollmentSelection enrollments={enrollments.data} selectedClassName="UnitListBoxLos"/></div>
                                        : <EnrollmentSelection defaultEnrollment={defaultEnrollment} enrollments={enrollments.data}/>}
                                    <AssessmentsWidget enrollments={filtered} />
                                    {isVet ? <WeeklyActivityWidget enrollments={filtered} />
                                        : <div style={{display:'flex', flexDirection:'row'}}>
                                                <AssessmentExtensionsWidget enrollments={filtered} />
                                                <WeeklyActivityWidget enrollments={filtered} />
                                    </div> }
                                    <SectionActivityWidget enrollments={filtered} />
                                </div>
                                <div className="studentDetailsRightColumn">
                                    {courseEnrollments.map( crsEnr => <ProgressInCourseWidget key={crsEnr.id} courseEnrollment={crsEnr} />)}
                                    <div style={{clear: 'both'}}>
                                        <DaysSinceLogin enrollments={filtered}/>
                                        {isVet ? <AssessmentsSubmitted enrollments={filtered} />
                                            : <AssessmentsDue enrollments={filtered} />}
                                    </div>
                                </div>
                            </div>
                        )}
                        { tab === 'interactions' && (
                            <>
                                {isVet ? <div><VetEnrollmentSelection enrollments={enrollments.data} selectedClassName="UnitListBoxInteractions"/></div>
                                    : <EnrollmentSelection defaultEnrollment={defaultEnrollment} enrollments={enrollments.data}/>}
                                <InteractionGraph term={term} enrollments={filtered} interactionCases={defaultEnrollment.interactionCases}/>
                                <InteractionTable enrollments={filtered} interactionCases={defaultEnrollment.interactionCases} totalInteractions={totalInteractions}  strategies={home.config.strategies}/>
                            </>
                        )}
                        { tab === 'details' && (
                            <div className='flexRow'>
                                <StudentDetails incognito={home.incognito} student={student} enrollments={enrollments.data} />
                            </div>
                        )}
                    </div>
                </main>
            </SamActivityProvider>
        </AssessmentProvider>
    )
}

export default StudentDashboard;